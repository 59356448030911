<template>
  <div class="dashboard__container" v-if="user">
    <div class="dashboard__container--body ">
      

      <div class="dashboard__container--body--col" v-if="user && user.interview">
        <div>
        <h4 class="mb-3">Interview Questions</h4>
          <div class="mb-3">
            <label for="when">When are they able to start?</label>
            <input  type="date" placeholder="" v-model.trim="user.interview.when" id="when" @blur="updateUser()" />
          </div>
          <div class="mb-3">
            <label for="shifts">What shifts are they able to work?</label>
            <input  type="text" placeholder="" v-model.trim=" user.interview.shifts" @blur="updateUser()" id="shifts" />
          </div>
          <div class="mb-3">
            <label for="amazon">Have they worked at Amazon before?</label>
            <v-select
              label="title" 
              :options="yesNo"
              v-model="user.interview.amazon"
              @input="updateUser()"
              >
            </v-select>
          </div>
          <div class="mb-3">
            <label for="legal">Are they legally allowed to work in the United States?</label>
            <v-select
              label="title" 
              :options="yesNo"
              v-model="user.interview.legal"
              @input="updateUser()"
              >
            </v-select>
          </div>
          <div class="mb-3">
            <label for="standing">Are they comfortable standing and walking for long periods of time?</label>
            <v-select
              label="title" 
              :options="yesNo"
              v-model="user.interview.standing"
              @input="updateUser()"
              >
            </v-select>
          </div>

          <div class="mb-3">
            <label for="forty">Are they able to lift and move a box that weighs 40lbs?</label>
            <v-select
              label="title" 
              :options="yesNo"
              v-model="user.interview.forty"
              @input="updateUser()"
              >
            </v-select>
          </div>

          <div class="mb-3">
            <label for="background">Is there anything we should be aware of when we run your background check?</label>
            <v-select
              label="title" 
              :options="yesNo"
              v-model="user.interview.background"
              @input="updateUser()"
              >
            </v-select>
          </div>

          <transition name="fade">
            <div class="mt-3 mb-3" v-if="user && user.interview && user.interview.background && user.interview.background == 'Yes'">
              <label for="explain">Please explain:</label>
              <input type="text" placeholder="" v-model.trim="user.interview.explain" id="explain" />
            </div>
          </transition>

          <div class="mb-3">
            <label for="passDrugTest">Are you confident that you would pass a drug test? (excluding marijuana)</label>
            <v-select
              label="title" 
              :options="yesNo"
              v-model="user.interview.passDrugTest"
              @input="updateUser()"
              >
            </v-select>
          </div>

          <div class="mb-3">
            <label for="transportation">Do you have reliable transportation?</label>
            <v-select
              label="title" 
              :options="yesNo"
              v-model="user.interview.transportation"
              @input="updateUser()"
              >
            </v-select>
          </div>

          <div class="mb-3">
            <label for="nightsWeekends">Are they able to work nights or weekends?</label>
            <v-select
              label="title" 
              :options="yesNo"
              v-model="user.interview.nightsWeekends"
              @input="updateUser()"
              >
            </v-select>
          </div>

          <div class="mb-3">
            <label for="experience">What experience do they have?</label>
            <textarea name="experience" id="" cols="30" rows="3" v-model.trim="user.interview.experience" @blur="updateUser()" ></textarea>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as moment from 'moment'
const fb = require('../../firebaseConfig.js')

export default {
  props: ['user'],
  data: () => ({
    performingRequest: false,
    types: ['Food and Beverage', 'Janitorial', 'Guest Services', 'Housekeeping'],
    hours: ['0-10', '10-20', '20-30', '30-40'],
    yesNo: ['Yes', 'No'],
    form: {    },
  }),
  name: 'userInterview',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
  },
  mounted() {
    this.addInt()
  },
  methods: {
    addInt() {
      if (this.user && !this.user.interview) {
        fb.usersCollection.doc(this.user.id).update({
          interview: {}
        })
      }
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMM Do, YYYY')
      } else {
        return null
      }
    },
    updateUser() {
      fb.usersCollection.doc(this.user.id).update(this.user)
    },
    // updateUser() {
    //   console.log(this.$route.params.id)
    //   console.log(this.user.id)
    //   fb.usersCollection.doc(this.$route.params.id).update({
    //     interview: this.user.interview
    //   })
    // }
  },
}

</script>